<template>
  <b-button
    class="mr-2"
    size="sm"
    @click="$emit('filter-activated')"
    :variant="color">
    {{status}} ({{ counter }})
  </b-button>
</template>

<script>
import integer from 'vuelidate/lib/validators/integer';

export default {
  name: 'claims-batch-overview',
  props: {
    status: {
      type: String,
      default: null,
    },
    counter: {
      type: integer,
      default: 0,
    },
    selected: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    color() {
      if (this.selected) {
        switch (this.status) {
          case 'PAID':
            return 'success';
          case 'FAILED':
            return 'danger';
          case 'MANUAL_ACTION':
            return 'warning';
          default:
            return 'secondary';
        }
      }
      switch (this.status) {
        case 'PAID':
          return 'outline-success';
        case 'FAILED':
          return 'outline-danger';
        case 'MANUAL_ACTION':
          return 'outline-warning';
        default:
          return 'outline-secondary';
      }
    },
  },
};
</script>
