<template>
  <div class="p-3">
    <header class="mb-4">
      <h3>{{ translations.page_title }}</h3>
    </header>
      <div class='col-9 mb-5'>
        <claims-batch-overview
          v-for='overview in claimsBatchesOverview' :key='overview.status'
          :status='overview.status'
          :selected="statusFilterIsset(overview.status)"
          @filter-activated="setStatusFilter(overview.status)"
          :counter='overview.count'>
        </claims-batch-overview>
      </div>
    <financial-list
      v-model="currentPage"
      :header-data="TABLE_HEADERS"
      :events="TABLE_EVENTS"
      :data="claimsBatches"
      :disabled="loading"
      :items-per-page="itemsPerPage"
      :total="total"
      :no-items-message="translations.claims_batches_table.empty_claims_batches_list"
      itemRefKey="id"
      @page-changed="loadClaimsBatchesList"
      @row-details="openDetails"
      @row-export-837-file="export837File"
      @row-export-837-file-sample="export837FileSample"/>
  </div>
</template>

<script>
import moment from 'moment';
import translations from '@/translations';
import { formatValue, formatDate, formatDatetime } from '@/helpers/finance';
import utils from '@/scripts/tools/utils';
import FinancialList, { associateHeaderDataItem } from '@/components/Financial/FinancialList.vue';
import ClaimsBatchOverview from '@/components/Financial/ClaimsBatchOverview.vue';

export default {
  components: { FinancialList, ClaimsBatchOverview },
  name: 'FinanceClaimsBatchesList',
  created() {
    this.TABLE_HEADERS = [
      associateHeaderDataItem(this.translations.claims_batches_table.id, 'id'),
      associateHeaderDataItem(this.translations.claims_batches_table.client_name, 'statement.client_name'),
      associateHeaderDataItem(this.translations.claims_batches_table.statement_id, 'statement.id'),
      associateHeaderDataItem(this.translations.claims_batches_table.total_balance, 'total_balance'),
      associateHeaderDataItem(this.translations.claims_batches_table.payer_name, 'statement.statement_configuration.payer.payer_name'),
      associateHeaderDataItem(this.translations.claims_batches_table.provider_name, 'statement.statement_configuration.billing_provider.name'),
      associateHeaderDataItem(this.translations.claims_batches_table.status, 'status'),
      associateHeaderDataItem(this.translations.claims_batches_table.created_at, 'created_at'),
    ];
    this.TABLE_EVENTS = {
      'row-details': {
        title: translations.finance.financial_list.icons.details,
        icon: 'eye',
      },
      'row-export-837-file': {
        title: translations.finance.financial_list.icons.export_837_file,
        icon: 'file-text',
      },
      'row-export-837-file-sample': {
        title: translations.finance.financial_list.icons.export_837_file_sample,
        icon: 'file-minus',
      },
    };
  },
  data() {
    return {
      translations: translations.finance.claims_batches,
      loading: true,
      claimsBatches: [],
      claimsBatchesOverview: {},
      statusFilter: '',
      itemsPerPage: 10,
      currentPage: 1,
      total: 0,
    };
  },
  beforeMount() {
    this.loadClaimsBatchesList(this.currentPage);
    this.loadClaimsBatchesOverview();
  },
  methods: {
    setStatusFilter(status) {
      if (status === this.statusFilter) {
        this.statusFilter = '';
      } else {
        this.statusFilter = status;
      }
      this.loadClaimsBatchesList(1);
    },
    statusFilterIsset(status) {
      return status === this.statusFilter;
    },
    async loadClaimsBatchesOverview() {
      try {
        const claimsBatchesOverview = await this.$store.dispatch('Financial/getClaimsBatchesOverview');
        this.claimsBatchesOverview = claimsBatchesOverview.data.data;
      } catch (e) {
        this.$noty.error(`${this.translations.errors.fetch_overview_error}`);
      }
    },
    async loadClaimsBatchesList(page) {
      this.loading = true;
      const filters = {
        offset: (page - 1) * this.itemsPerPage,
        limit: this.itemsPerPage,
        order_descending: true,
        order_by: 'id',
      };
      if (this.statusFilter) {
        filters.status = this.statusFilter;
      }
      try {
        const { data: { data: apiClaimsBatches } } = await this.$store
          .dispatch('Financial/getClaimsBatches', filters);
        if (!apiClaimsBatches) {
          this.total = 0;
          this.claimsBatches = [];
        } else {
          this.total = apiClaimsBatches.total;
          this.claimsBatches = apiClaimsBatches.claims_batches;
        }
        this.claimsBatches.forEach(claimsBatch => {
          claimsBatch.date = formatDate(claimsBatch.date);
          claimsBatch.created_at = formatDatetime(claimsBatch.created_at);
          claimsBatch.total_balance = formatValue(claimsBatch.total_balance);
        });
      } catch (_) {
        this.$noty.error(`${this.translations.errors.fetch_list_error}.`);
      } finally {
        this.loading = false;
      }
    },
    openDetails(id) {
      this.$router.push(`/finance/claims-batches/${id}`);
    },
    async export837FileSample(id, claimsBatch) {
      this.export837File(id, claimsBatch, true);
    },
    async export837File(id, claimsBatch, sample) {
      try {
        const { data } = await this.$store.dispatch('Financial/export837File', { id, sample });
        const fileName = this.makeExport837Filename(claimsBatch, sample);
        utils.downloadFile(data, fileName);
      } catch (error) {
        let errorMsg = this.translations.errors.export_837_file_generic;
        if (error.response && error.response.status === 404) {
          errorMsg = this.translations.errors.export_837_file_not_available;
        }
        this.$noty.error(errorMsg);
      }
    },
    makeExport837Filename(claimsBatch, sample) {
      const clientName = `_${claimsBatch?.statement?.client_name}` || '';
      const payerName = `_${claimsBatch?.statement?.statement_configuration?.payer?.payer_name}` || '';
      const statementID = `_${claimsBatch?.statement?.id}` || '';
      const date = `_${moment(claimsBatch?.created_at).utc().format('YYYYMMDD')}` || '';
      const sampleTag = sample ? '_Sample' : '';

      let fileExtension = 'txt';
      if (claimsBatch?.statement?.statement_configuration?.clearing_house === 'waystar') {
        fileExtension = 'CLP';
      }

      return `SwordHealth_Claims${sampleTag}${clientName}${payerName}${statementID}${date}.${fileExtension}`;
    },
  },
};
</script>
